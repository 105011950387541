<template>
  <div id="dashboard3-m">
    <v-tabs v-model="tab">
      <v-tab>测试记录统计</v-tab>
      <v-tab>测试结果分析</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row class="mt-5">
          <v-col cols="12" md="5">
            <!-- 当天测试记录 -->
            <ChartPie
              :chartTitle="todayTestSummary.chartTitle"
              :chartName="todayTestSummary.chartName"
              :chartXData="todayTestSummary.chartData"
              :showData="todayTestSummary.showPieData"
            ></ChartPie>
          </v-col>
          <v-col offset-md="1" cols="12" md="5">
            <!-- 历史数量统计 -->
            <ChartPie
              :chartTitle="testSummary.chartTitle"
              :chartName="testSummary.chartName"
              :chartXData="testSummary.chartData"
              :showData="testSummary.showPieData"
            ></ChartPie>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="mt-5">
          <v-container>
            <v-select
              :items="testTypeOptions"
              label="测试类型"
              outlined
              v-model="testType"
            ></v-select>
          </v-container>
          <v-col cols="12" md="6">
            <!-- 完成率 -->
            <ChartLine
              :chartTitle="completeRate.chartLineTitle"
              :chartName="completeRate.chartLineName"
              :chartXData="completeRate.xData"
              :chartYData="completeRate.yData"
              :averageLine="completeRate.averageLine"
              :maxPoint="completeRate.maxPoint"
              :minPoint="completeRate.minPoint"
              :percent="true"
            ></ChartLine>
          </v-col>

          <v-col cols="12" md="6">
            <!-- 时间统计 -->
            <ChartLine
              :chartTitle="totalTime.chartLineTitle"
              :chartName="totalTime.chartLineName"
              :chartXData="totalTime.xData"
              :chartYData="totalTime.yData"
              :averageLine="totalTime.averageLine"
              :maxPoint="totalTime.maxPoint"
              :minPoint="totalTime.minPoint"
              :yUnit="'秒'"
            ></ChartLine>
          </v-col>
          <v-col cols="12" md="6">
            <!-- 正确率 -->
            <ChartLine
              :chartTitle="correctRate.chartLineTitle"
              :chartName="correctRate.chartLineName"
              :chartXData="correctRate.xData"
              :chartYData="correctRate.yData"
              :averageLine="correctRate.averageLine"
              :maxPoint="correctRate.maxPoint"
              :minPoint="correctRate.minPoint"
              :percent="true"
            ></ChartLine>
          </v-col>
          <v-col cols="12" md="6">
            <!-- 单位时间统计 -->
            <ChartLine
              :chartTitle="averageTime.chartLineTitle"
              :chartName="averageTime.chartLineName"
              :chartXData="averageTime.xData"
              :chartYData="averageTime.yData"
              :averageLine="averageTime.averageLine"
              :maxPoint="averageTime.maxPoint"
              :minPoint="averageTime.minPoint"
              :yUnit="'秒'"
            ></ChartLine>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BaseDashboard from '@/views/common/BaseDashboard.vue'

export default {
  data() {
    return {
      // TAB
      tab: null,
    }
  },
  extends: BaseDashboard
}
</script>

<style lang="scss" scoped>
</style>
