<template>
  <div id="user">
    <h3 class="mt-5 grey--text">历史测试记录</h3>
    <!-- 用户数据Data Table -->
    <v-data-table
      :headers="headers"
      :items="records"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      class="elevation-1 mt-8"
      disable-sort
    >
      <!-- Data Table 上部信息，包括新增按钮，查询框等 -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 100%">测试类型：</v-toolbar-title>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <!-- <v-spacer></v-spacer> -->
          <v-select
            :items="testTypeOptions"
            v-model="testType"
            style="margin-left: 20px; margin-top: 15px"
          ></v-select>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field> -->
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="checkError(item)"
          v-if="!disableCheckErrors(item)"
        >
          mdi-magnify
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import BaseUserHistory from '@/views/common/BaseUserHistory.vue'
import { TEST_TYPES } from '@/const'

export default {
  mounted() {
    this.testTypeOptions = [...TEST_TYPES]
    this.testTypeOptions.push('全部')
  },
  watch: {
    testType: {
      handler() {
        if (this.testType === '全部') {
          this.search = ''
        } else {
          this.search = this.testType
        }
      }
    }
  },
  data() {
    return {
      // 测试类型列表
      testTypeOptions: [],
      testType: '全部',
      //   表头
      headers: [
        {
          text: '测试ID',
          align: 'start',
          value: 'test_code',
        },
        { text: '类型', value: 'test_type' },
        { text: '完成率', value: 'complete_rate' },
        { text: '正确率', value: 'correct_rate' },
        { text: '花费时长', value: 'spend_str' },
        { text: '测试日期', value: 'test_date' },
        { text: '查看错题', value: 'actions', sortable: false },
      ],
    }
  },

  extends: BaseUserHistory,
}
</script>

<style lang="scss" scoped>
</style>
