<template>
  <nav>
    <v-app-bar flat app color="primary" dark>
      <!-- snackbar -->
      <v-snackbar v-model="snackbar" :timeout="2000" top shaped>
        {{ snackBarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!-- 左侧导航栏控制按钮 -->
      <v-app-bar-nav-icon
        class="white--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <!-- 上方系统名称显示 -->
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">EZ-</span>
        <span>LOGIC</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-chip
        class="ma-2"
        color="green"
        text-color="white"
        @click="showTrial()"
        v-if="isTrial"
        >试用账号</v-chip
      > -->

      <!-- 上方工具栏菜单 -->
      <v-icon v-if="!isTrial">mdi-account</v-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text color="white" v-on="on">
            <span>{{ currentUser }}</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <!-- <v-btn plain color="white" @click="signOut()">
          <span>退出登录</span>
          <v-icon right>mdi-exit-to-app</v-icon>
        </v-btn> -->
        <v-list>
          <v-list-item
            v-for="(item, index) in getbarMenu"
            :key="index"
            @click="item.func()"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- 修改密码对话框 -->
      <v-dialog id="updPwd" v-model="updPwdDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">修改密码</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" v-model="validUpdPwd" class="mt-5 pa-10">
              <v-row>
                <!-- <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="oldPassword"
                    label="请输入原密码"
                    :rules="[rules.password]"
                    :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showOldPassword ? 'text' : 'password'"
                    @click:append="showOldPassword = !showOldPassword"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="newPassword"
                    label="请输入新密码"
                    :rules="[rules.password]"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNewPassword ? 'text' : 'password'"
                    @click:append="showNewPassword = !showNewPassword"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    label="请确认新密码"
                    :rules="[rules.samePwd]"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNewPassword ? 'text' : 'password'"
                    @click:append="showNewPassword = !showNewPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelUpdPwdDialog">
              取消
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveNewPassword"
              :disabled="!validUpdPwd"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 演示账号说明对话框 -->
      <v-dialog
        id="trial-diag"
        v-model="trialAccountDialog"
        max-width="550px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="headline">演示账号</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mt-5">
              当前为演示账号，仅提供演示题目练习。请自行注册个人账号，获取完整练习题目。
            </p>
            <p>
              可添加助手微信ezlogic咨询详情。
            </p>
            <v-row>
              <img src="" alt="" />
              <img
                src="../../assets/assistantCode.jpg"
                alt=""
                style="
                  display: inline-block;
                  width: 200px;
                  height: 220px;
                  margin: 0 auto;
                "
              />
            </v-row>
            <v-divider class="mt-3"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="trialAccountDialog = false">
                确认 </v-btn
              >-
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- 试用账号说明对话框 -->
      <v-dialog
        id="trial-diag"
        v-model="trialDialog"
        max-width="550px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="headline">试用账号</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mt-5">
              当前为试用账号，有效期到 {{ userValidDate }}。 <br />
              试用账号每天每种题型练习可体验一次，题目数量有所限制。<br />
              更新为普通账号后，每天练习次数不受限制，<br>
              每次练习题目数量参照真实测试设置。
            </p>
            <p>
              如需升级普通账号，请添加助手微信咨询详情 <br />
              或关注微信公众号 “ez思维” <br />
            </p>
            <v-row>
              <img src="" alt="" />
              <img
                src="../../assets/assistantCode.jpg"
                alt=""
                style="
                  display: inline-block;
                  width: 200px;
                  height: 220px;
                  margin: 0 auto;
                "
              />
            </v-row>
            <v-divider class="mt-3"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="trialDialog = false">
                确认 </v-btn
              >-
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- <v-dialog
        id="trial-diag"
        v-model="trialDialog"
        max-width="550px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="headline">试用账号</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mt-5">
              当前为试用账号，每种测试类型仅提供少量试用题目。且有试用次数限制。
            </p>
            <p>
              升级普通账号后，即可获取全部题目，升级方式：<br />
              1. 使用手机号注册个人账号；<br />
              2. 扫码支付￥299.00元成为正式用户，有效期1年;<br />
              3. 付款时一定备注注册手机号，系统将在12小时内升级账号。<br />
              可添加助手微信ezlogic咨询详情
            </p>
            <p>注：5月1日前升级优惠价格￥199.00元。</p>
            <v-row>
              <img src="" alt="" />
              <img
                src="../../assets/pay/payCode.jpg"
                alt=""
                style="
                  display: inline-block;
                  width: 200px;
                  height: 220px;
                  margin: 0 auto;
                "
              />
            </v-row>
            <v-divider class="mt-3"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="trialDialog = false">
                确认 </v-btn
              >-
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog> -->
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      width="200px"
      height="100%"
      app
      class="primary"
      id="navdrawer-m"
    >
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-avatar size="100" @click="showDashboard">
            <img v-bind:src="imgUrl" alt="" />
          </v-avatar>
          <p class="username">{{ currentUser }}</p>
        </v-flex>
      </v-layout>

      <!-- 导航栏菜单 -->
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(item, i) in menu"
            :key="i"
            router
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon class="white--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="white--text">
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import BaseNavbar from '@/components/common/BaseNavbar.vue'

export default {
  data() {
    return {
      toolBarMenu: [
        { text: '统计分析', func: this.showDashboard },
        // { text: '用户中心', func: this.personalCenter },
        { text: '修改密码', func: this.changePassword },
        { text: '退出登录', func: this.signOut },
      ],
      // trial 菜单不允许修改密码
      toolBarMenuTrial: [
        { text: '练习数据', func: this.showDashboard },
        { text: '退出登录', func: this.signOut },
      ],
    }
  },
  extends: BaseNavbar,
}
</script>

<style lang="scss" scoped>
.username {
  text-align: center;
  color: white;
  font-size: medium;
  margin-top: 3px;
}
</style>
