<template>
  <div id="mental-calc-m">
    <h3 class="mt-5 grey--text">心算练习</h3>
    <v-row class="mt-5">
      <v-btn-toggle
        v-model="selectedDifficulty"
        borderless
        background-color="primary"
        style="margin: 0 auto"
      >
        <v-btn
          v-for="(v, k) in gameConfig"
          :key="'difficulty' + k"
          :value="k"
          >{{ v }}</v-btn
        >
      </v-btn-toggle>
    </v-row>
    <v-row>
      <table
        border="0"
        cellspacing="10"
        align="center"
        width="100%"
        class="mt-3"
      >
        <tr>
          <td align="center" class="highest-record">
            <p class="cell-word">计时器</p>
            <p>{{ counterToS }}秒</p>
          </td>
          <td align="center" class="highest-record">
            <p class="cell-word">最高纪录</p>
            <p>{{ getHighestRecord() }}秒</p>
          </td>
        </tr>
      </table>
    </v-row>
    <v-row>
      <v-container v-show="!gameStart" class="mt-5">
        <ChartLine
          :chartTitle="chartTitle"
          :chartName="chartName"
          :chartXData="xData"
          :chartYData="yData"
          :averageLine="averageLine"
          :maxPoint="true"
          :minPoint="true"
          :yUnit="'秒'"
        >
        </ChartLine>
      </v-container>
      <v-container class="mt-2" v-if="gameStart">
        <v-row>
          <v-col
            cols="2"
            v-for="(item, idx) in gameInfo.gameData"
            :key="'checkbox' + idx"
          >
            <v-checkbox
              readonly
              id="idx"
              :label="'' + (idx + 1)"
              v-model="item.result"
            >
            </v-checkbox
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <p class="parm-style">{{ parmA }}</p>
          </v-col>
          <v-col cols="2">
            <p class="parm-style">X</p>
          </v-col>
          <v-col cols="5">
            <p class="parm-style">{{ parmB }}</p>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col offset="1" cols="10">
            <v-text-field
              ref="userInput"
              class="input-result"
              v-model="userResult"
              label="请输入答案"
              autocomplete="off"
              hint="按回车键提交答案"
              clearable
              outlined
              width="80%"
              @keyup.enter="checkResult"
              @click:clear="clearResult"
            ></v-text-field>
          </v-col>
          <!-- <input type="text" ref="text-input" /> -->

          <p class="error-tip">{{ errorTip }}</p>
        </v-row>
      </v-container>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-if="!gameStart"
          @click="startGame()"
        >
          开始游戏
        </v-btn>
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-if="gameStart"
          @click="stopGame()"
        >
          停止游戏
        </v-btn>
      </v-row>
    </v-row>
    <!-- 结束游戏对话框 -->
    <v-dialog v-model="showResult" max-width="400px">
      <v-card>
        <v-card-title class="headline"> 游戏完成 </v-card-title>
        <v-card-text>
          <v-container>
            <p v-if="newRecord" class="new-record">恭喜获得新纪录!</p>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  label="游戏难度"
                  class="text-align--center"
                  v-model="gameConfig[gameInfo.difficulty]"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  label="最高记录"
                  v-model="highRecords"
                  align="center"
                  suffix="秒"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  label="当前记录"
                  v-model="gameInfo.spendTimeS"
                  suffix="秒"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  label="平均手速"
                  suffix="秒"
                  v-model="gameInfo.averageRate"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogConfirm()"
            >确认</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseGameComponent from '@/components/common/BaseGameComponent'
// import timeCounterComponent from '@/components/common/timeCounterComponent'
import { getRandomInt } from '@/utils'
import {
  GAME_MODE_NORMAL,
  GAME_TYPE_MENTAL_CALC,
  GAME_MENTAL_CALC_SUB_COUNT,
} from '@/const'
import ChartLine from '@/components/charts/eChartLine'

export default {
  components: { ChartLine },
  mounted() {
    this.retrieveHighestRecord()
    this.retrieveUserGameRecords()
  },
  watch: {
    selectedDifficulty: {
      handler() {
        if (this.gameStart === true) {
          // 当前游戏进行中，不操作
          return
        }
        this.gameInfo.difficulty = this.selectedDifficulty
        this.retrieveUserGameRecords()
      },
    },
    curIdx: {
      handler() {
        if (this.curIdx === -1) {
          return
        }
        this.updateParms()
      },
      deep: true,
    },
    gameStart: {
      handler() {
        if (this.gameStart === true) {
          // 输入框通过gameStart字段控制，设置时html模板未被加载，无法获取输入框实例
          // 使用异步延迟机制即可解决，Promise比SetTimeout优先级更高，二者均可
          // setTimeout(() => {
          // }, 0)
          Promise.resolve().then(() => {
            this.$refs.userInput.focus()
          })
        }
      },
    },
  },
  data() {
    return {
      // 输入结果
      userResult: null,
      //   游戏难度配置
      gameConfig: {
        easy: '1 X 2',
        normal: '2 X 2',
        hard: '3 X 2',
        nightmare: '3 X 3',
      },
      //   游戏信息
      gameInfo: {
        gameType: GAME_TYPE_MENTAL_CALC,
        gameMode: GAME_MODE_NORMAL,
        gameSubCount: GAME_MENTAL_CALC_SUB_COUNT,
        gameData: [],
        difficulty: 'normal',
        startTime: null,
        endTime: null,
        spendTimeMS: null,
        spendTimeS: null,
        averageRate: null,
      },
      // 当前题目索引
      curIdx: 0,
      // 页面显示参数
      parmA: null,
      parmB: null,
      // 错误提示信息
      errorTip: null,
      // echart参数
      chartName: '游戏时间',
    }
  },
  methods: {
    // // 生成乘法计算题
    generateMultipy(parmRange) {
      const aMin = parmRange[0][0]
      const aMax = parmRange[0][1]
      const bMin = parmRange[1][0]
      const bMax = parmRange[1][1]
      const parmA = getRandomInt(aMax, aMin)
      const parmB = getRandomInt(bMax, bMin)
      const answer = parmA * parmB
      return {
        parmA: parmA,
        parmB: parmB,
        answer: answer,
        result: false,
      }
    },
    // 重载方法：生成计算题
    generateGameData() {
      const levelMap = {
        easy: [
          [10, 99],
          [1, 9],
        ],
        normal: [
          [10, 99],
          [10, 99],
        ],
        hard: [
          [100, 999],
          [10, 99],
        ],
        nightmare: [
          [100, 999],
          [100, 999],
        ],
      }
      const parmRange = levelMap[this.gameInfo.difficulty]
      this.gameInfo.gameData = []
      for (let idx = 0; idx < GAME_MENTAL_CALC_SUB_COUNT; idx++) {
        this.gameInfo.gameData.push(this.generateMultipy(parmRange))
      }
    },
    // 重载方法：配置启动游戏参数
    configStartGameParms() {
      this.userResult = null
      this.curIdx = 0
      this.updateParms()
    },
    // 重载方法：停止游戏，重置参数
    setStopGameParms() {
      this.userResult = null
      this.updateParms()
      // 设置为-1，表示无效
      this.curIdx = -1
    },
    // 检查输入计算结果
    checkResult() {
      if (
        parseInt(this.userResult) !== this.gameInfo.gameData[this.curIdx].answer
      ) {
        this.errorTip = '答案错误, 请重试!'
        return
      }
      // 结果正确
      this.errorTip = null
      this.gameInfo.gameData[this.curIdx].result = true

      if (this.curIdx === this.gameInfo.gameSubCount - 1) {
        // 当前为最后一题，结束
        this.endGame()
      } else {
        this.curIdx += 1
        this.userResult = null
      }
    },
    // 更新页面参数
    updateParms() {
      if (this.gameInfo.gameData.length === 0) {
        this.parmA = null
        this.parmB = null
      } else {
        this.parmA = this.gameInfo.gameData[this.curIdx].parmA
        this.parmB = this.gameInfo.gameData[this.curIdx].parmB
      }
    },
    // 清除输入结果
    clearResult() {
      this.errorTip = null
    },
  },
  computed: {
    highRecords() {
      return this.getHighestRecord()
    },
  },
  extends: BaseGameComponent,
}
</script>

<style lang="scss" scoped>
.table {
  align-content: center;
  margin: auto;
}
.cell-word {
  font-size: small;
  color: white;
  align-content: center;
}
.highest-record {
  font-size: 150%;
  font-weight: bold;
  background: rgb(106, 182, 135);
  color: blue;
  // background-color: khaki;
  padding: 1% 0 1% 0;
}
.new-record {
  color: red;
  font-size: x-large;
}
.parm-style {
  text-align: center;
  font-size: 450%;
  padding: auto;
  // margin: 0 auto;
  color: darkblue;
}
.input-result {
  font-size: 150%;
  padding: 0%;
  // margin-top: 15px;
  margin: auto;
}
.error-tip {
  font-size: 15px;
  color: red;
}
.v-application p {
  margin-bottom: 1px;
}
.row + .row {
  margin-top: 0px;
}
</style>
