<template>
  <div id="optionsComponent-m">
    <h3 class="mt-2 h6 grey--text">{{ testConfig.title }}</h3>
    <v-container class="mt-2">
      <!-- 题目数量及定时器 -->
      <v-row v-show="isTestData">
        <v-col cols="6">
          <h1 class="mt-3 subtitle-1 grey--text">
            第 {{ curQNum + 1 }} 题，共 {{ qCount }} 题
          </h1>
        </v-col>
        <v-col offset="1" ms="4">
          <time-counter-component
            :counterStart="counterStart"
            :intervalMs="timerIntervalMs"
          >
          </time-counter-component>
        </v-col>
      </v-row>
      <!-- 题目图片 -->
      <v-row class="mt-2">
        <img
          v-bind:src="qImg"
          style="
            display: inline-block;
            width: 90%;
            max-width: 90%;
            height: 300px;
            margin: 0 auto;
          "
        />
      </v-row>
      <!-- 答案选项 -->
      <v-row v-if="isTestData">
        <v-col cols="12">
          <v-radio-group row v-model="selectAnswer" style="height: 50px">
            <v-radio
              v-for="item in answerOptions"
              :key="item"
              :label="item"
              :value="item"
              style="margin-right: 5%; margin-left: 5%; margin-bottom: 1%; margin-top: 1%"
            ></v-radio>
          </v-radio-group>
          <br />
        </v-col>
      </v-row>
      <!-- 按钮 -->
      <v-row>
        <v-btn
          style="margin-left: 5%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="preQuestion()"
          :disabled="disablePreBtn()"
        >
          上一题
        </v-btn>
        <v-btn
          style="margin-left: 20%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="nextQuestion()"
          :disabled="disableNextBtn()"
        >
          下一题
        </v-btn>
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-if="isTestStart"
          @click="doneTest()"
          :loading="loadingSubmit"
        >
          提交答案
        </v-btn>
        </v-row>
        <v-row class="mt-5">
        <v-btn
          style="margin-left: 5%"
          width="90%"
          height="60px"
          color="primary"
          v-show="checkErrors"
          @click="setAnswerVisible()"
        >
          <span v-if="!answerVisible">显示答案</span>
          <span v-if="answerVisible">隐藏答案</span>
        </v-btn>
        <v-textarea
          label="正确答案"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          v-model="correctAnswer"
            v-if="showAnswer"
            readonly
            style="
              margin-right: 5%;
              margin-left: 5%;
              font-size: 100%;
              margin-top: 20px;
            "
        ></v-textarea>
        <!-- <v-text-field
          max-width="20%"
          style="margin-right: 5%; margin-left: 5%"
          label="正确答案"
          v-model="correctAnswer"
          v-if="showAnswer"
          readonly
        ></v-text-field> -->
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-show="!isTestStart"
          @click="startTest()"
        >
          开始测试
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent'
import { answerOptions } from '@/utils'
import timeCounterComponent from '@/components/common/timeCounterComponent'

export default {
  components: { timeCounterComponent },
  methods: {
    // 根据答案显示选项
    getAnswerOptions() {
      const count = this.questions[this.curQNum].sub_count
      const answer = this.questions[this.curQNum].q_answer
      this.answerOptions = answerOptions(count, answer)
      this.selectAnswer = this.questions[this.curQNum].q_result
    },
  },
  computed: {
    // 正确答案
    correctAnswer() {
      return (
        this.questions[this.curQNum].q_answer +
        '.  ' +
        this.questions[this.curQNum].remark
      )
    },
  },
  extends: BaseComponent,
}
</script>

<style lang="scss" scoped>
.btn {
  margin-top: 15px;
  font-size: 20px;
  width: 150px;
  height: 60px;
  color: #415ae9;
}
.radio {
  font-size: 200%;
}
</style>
