<template>
  <div id="solidRotation">
    <input-component
      :testConfig="testConfig"
      :questions="questions"
      :testData="testData"
      :initImage="initImage"
      @doneTest="doneTest"
      @startTest="startTest"
      @submitAnswer="submitAnswer"
    ></input-component>
    <wait-result-dialog
      id="resultDialog"
      :testResult="testResult"
      :showDialog="waitResultDialog"
      @closeResultDialog="closeResultDialog"
      @checkErrors="checkErrors"
    ></wait-result-dialog>
  </div>
</template>

<script>
import InputComponent from '@/components/mobile/InputPageComponent.vue'
import waitResultDialog from '@/components/mobile/waitRespDialog.vue'
import BaseSolidSpread from '@/views/common/BaseSolidSpread.vue'

export default {
  components: { InputComponent, waitResultDialog },

  extends: BaseSolidSpread,
}
</script>

<style lang="scss" scoped>
</style>
