<template>
  <div id="demo-pc">
    <v-card class="mx-auto" max-width="80%">
      <v-card-title>
        <v-icon large left> mdi-twitter </v-icon>
        <span class="title font-weight-light">欢迎试用逻辑测试系统</span>
      </v-card-title>

      <v-card-text class="headline font-weight-bold">
        登陆中，请稍后
      </v-card-text>
      <v-progress-linear
        indeterminate
        color="primary"
        class="mt-5 mb-15"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import BaseDemo from '@/views/common/BaseDemo.vue'

export default {

  data() {
    return {
      username: 'demouser',
      password: '11111111',
      loginUrl: 'auth/app-login'
    }
  },

  extends: BaseDemo,
}
</script>

<style lang="scss" scoped>
</style>
