<template>
  <div id="pattern-match-m">
    <pattern-match-component
      :testConfig="testConfig"
      :questions="questions"
      :testData="testData"
      :initImage="initImage"
      :qImgCount="qImgCount"
      @doneTest="doneTest"
      @startTest="startTest"
      @submitAnswer="submitAnswer"
    ></pattern-match-component>
    <wait-result-dialog
      id="resultDialog"
      :testResult="testResult"
      :showDialog="waitResultDialog"
      @closeResultDialog="closeResultDialog"
      @checkErrors="checkErrors"
    ></wait-result-dialog>
  </div>
</template>

<script>
import patternMatchComponent from '@/components/mobile/PatternMatchComponent.vue'
import waitResultDialog from '@/components/mobile/waitRespDialog.vue'
import BasePatternMatch from '@/views/common/BasePatternMatch.vue'

export default {
  components: { patternMatchComponent, waitResultDialog },
  data() {
    return {
      qImgCount: 15
    }
  },
  extends: BasePatternMatch,
}
</script>

<style lang="scss" scoped>
</style>
