<template>
  <div id="pattern-match-com-m">
    <h3 class="mt-5 grey--text">{{ testConfig.title }}</h3>
    <v-container class="mt-5">
      <!-- 题目数量及定时器 -->
      <v-row v-show="isTestData">
        <v-col cols="5">
          <h1 class="mt-3 subtitle-1 grey--text">
            第 {{ curQNum + 1 }} 页，共 {{ qCount }} 页
          </h1>
        </v-col>
        <v-col offset="1" ms="4">
          <time-counter-component
            :counterStart="counterStart"
            :intervalMs="timerIntervalMs"
          >
          </time-counter-component>
        </v-col>
      </v-row>
      <v-row v-if="!isTestData">
        <!-- 题目说明，未开始测试时显示 -->
        <img
          v-bind:src="qImg"
          style="
            display: inline-block;
            width: 90%;
            max-width: 90%;
            height: 400px;
            margin: 0 auto;
          "
        />
      </v-row>
      <v-row v-if="isTestData">
        <v-col
          offset-sm="1"
          sm="3"
          v-for="colIdx in qSubCount"
          :key="'col' + colIdx"
        >
          <!-- 开始测试后显示 -->
          <v-row>
            <v-col cols="4">
              <img
                v-for="item in sampleIdx[colIdx - 1].length"
                :key="'imgT' + item"
                v-bind:src="getImages(sampleIdx[colIdx - 1][item - 1])"
                class="imgTRight"
              />
              <v-divider class="mt-2 mb-2"></v-divider>
              <img
                v-for="i in imagesIdx[colIdx - 1].length"
                :key="'img' + i"
                v-bind:src="getImages(imagesIdx[colIdx - 1][i - 1])"
                class="imgRight"
              />
              </v-col>
              <v-col cols="3">
                <!-- 选择框 -->
                <img src="" alt="" class="nullImg" />
                <input
                  type="checkbox"
                  class="checkbox"
                  v-for="item in imagesIdx[colIdx - 1].length - 1"
                  :key="'checkbox' + item"
                  v-model="userResult[colIdx - 1]"
                  :value="item"
                />
              </v-col>
              <v-col cols="3">
                <!-- 答案显示框 -->
                <div v-if="showAnswer">
                  <img src="" alt="" class="nullImg" />
                  <input
                    type="checkbox"
                    class="answer-checkbox"
                    v-for="item in imagesIdx[colIdx - 1].length - 1"
                    :key="'answer' + item"
                    v-model="answers[colIdx - 1]"
                    :value="item"
                    disabled
                  />
                </div>
              </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-btn
          style="margin-left: 5%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="preQuestion()"
        >
          上一题
        </v-btn>
        <v-btn
          style="margin-left: 20%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="nextQuestion()"
        >
          下一题
        </v-btn>
      </v-row>

      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-if="isTestStart"
          @click="doneTest()"
          :loading="loadingSubmit"
        >
          提交答案
        </v-btn>
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-show="checkErrors"
          @click="setAnswerVisible()"
        >
          <span v-if="!answerVisible">显示答案</span>
          <span v-if="answerVisible">隐藏答案</span>
        </v-btn>
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-show="!isTestStart"
          @click="startTest()"
        >
          开始测试
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent'
import { SEPARATE_FLAG, SEPARATE_SUB_FLAG } from '@/const'
import { intCompare } from '@/utils'
import timeCounterComponent from '@/components/common/timeCounterComponent'

export default {
  components: { timeCounterComponent },

  name: 'pattern-match-component',
  data() {
    return {
      // 样本图片索引
      sampleIdx: [[], [], []],
      // 问题图片索引
      imagesIdx: [[], [], []],
      // 用户选择答案
      userResult: [],
      // 当前页面问题答案
      answers: [[], [], []],
      // // 每页题目数量
      // qSubCount: 0,
    }
  },
  methods: {
    // 获取图片
    getImages(idx) {
      if (idx === null) {
        return ''
      }
      const image_name = 'pattern_' + idx + '.jpg'
      return require('../../assets/patternImages/' + image_name)
    },
    // 根据题目信息，设置图像索引
    procQuestionInfo() {
      if (this.questions === null) {
        // 问题列表为空，返回
        return
      }
      // 将字符串转为JSON格式
      // eslint-disable-next-line quotes
      const qInfo = JSON.parse(
        this.questions[this.curQNum].q_info.replaceAll("'", '"')
      )
      // 设置答题信息
      const qResult = this.questions[this.curQNum].q_result.split(SEPARATE_FLAG)
      const result_len = qResult.length
      this.qSubCount = qInfo.length
      for (let idx = 0; idx < this.qSubCount - result_len; idx++) {
        qResult.push('')
      }
      for (let idx = 0; idx < qInfo.length; idx++) {
        this.sampleIdx[idx] = qInfo[idx].sample_idx
        this.imagesIdx[idx] = qInfo[idx].image_idx
        this.answers[idx] = qInfo[idx].answers
        if (qResult[idx] === '') {
          this.userResult[idx] = []
        } else {
          this.userResult[idx] = qResult[idx].split(SEPARATE_SUB_FLAG)
        }
      }
    },
    // 重载方法：问题序号更新
    procQnumUpdate() {
      this.procQuestionInfo()
    },
    // 重载方法：开始新测试
    procNewTest() {
      this.procQuestionInfo()
    },
    // 重载方法：读取当前问题答案
    getQuestionResult() {
      const result = []
      for (const item of this.userResult) {
        result.push(item.sort(intCompare).join(SEPARATE_SUB_FLAG))
      }
      return result.join(SEPARATE_FLAG)
    },
  },
  computed: {
    // getLocalImg() {
    //   if (this.signImage === '') {
    //     return this.signImage
    //   }
    //   return require('../assets/' + this.signImage)
    // },
  },
  extends: BaseComponent,
}
</script>

<style lang="scss" scoped>
.codeIndex {
  font-family: 微软雅黑;
  font-size: 26px;
  font-weight: bold;
  border-bottom: 1px dashed #cccccc;
  color: #255e95;
}
.imgTRight {
  position: float;
  // margin-left: 5px;
  margin-bottom: 5px;
  width: 30px;
  height: 30px;
}
.imgRight {
  position: float;
  // margin-left: 5px;
  margin-bottom: 5px;
  width: 30px;
  height: 30px;
}
.checkbox {
  position: float;
  width: 20px;
  height: 20px;
  margin-bottom: 22px;
  background-color: greenyellow;
}
.answer-checkbox {
  position: float;
  width: 20px;
  height: 20px;
  margin-bottom: 22px;
  background-color: grey;
}
.nullImg {
  position: float;
  width: 0px;
  height: 35px;
  margin-bottom: 110px;
}
</style>
