<template>
  <div id="inputComponent-m">
    <h3 class="mt-5 grey--text">{{ testConfig.title }}</h3>
    <v-container class="mt-5">
      <!-- 题目数量和计时器 -->
      <v-row v-show="isTestData">
        <v-col cols="5">
          <h1 class="mt-3 subtitle-1 grey--text">
            第 {{ curQNum + 1 }} 题，共 {{ qCount }} 题
          </h1>
        </v-col>
        <v-col offset="1" ms="4">
          <time-counter-component
            :counterStart="counterStart"
            :intervalMs="timerIntervalMs"
          >
          </time-counter-component>
        </v-col>
      </v-row>
      <v-row class="mt-5">
          <img
            v-bind:src="qImg"
            style="
              display: inline-block;
              width: 90%;
              max-width: 90%;
              height: 300px;
              margin: 0 auto;
            "
          />
      </v-row>
      <v-row>
        <v-container v-if="isTestData">
            <v-row>
              <v-col v-for="item in answerOptions" :key="item.index">
                <v-text-field
                  :rules="[rules.max]"
                  v-model="item.result"
                  :label="item.title"
                  clearable
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  v-if="answerVisible"
                  v-model="item.answer"
                  label="答案"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
        </v-container>
      </v-row>
      <v-row>
        <v-btn
          style="margin-left: 5%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="preQuestion()"
          :disabled="disablePreBtn()"
        >
          上一题
        </v-btn>
        <v-btn
          style="margin-left: 20%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="nextQuestion()"
          :disabled="disableNextBtn()"
        >
          下一题
        </v-btn>
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-show="isTestStart"
          @click="doneTest()"
          :loading="loadingSubmit"
        >
          提交答案
        </v-btn>
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-if="checkErrors"
          @click="setAnswerVisible()"
        >
          <span v-if="!answerVisible">显示答案</span>
          <span v-if="answerVisible">隐藏答案</span>
        </v-btn>
      </v-row>
      <v-row class="mt-5">
        <!-- 按钮 -->
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-show="!isTestStart"
          @click="startTest()"
        >
          开始测试
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent'
import timeCounterComponent from '@/components/common/timeCounterComponent'

export default {
  components: { timeCounterComponent },
  data() {
    return {
      // 规则检验
      rules: {
        max: (v) => v === null || v.length <= 1 || 'Max 1 characters',
      },
    }
  },
  methods: {
    getQuestionResult() {
      return this.answerOptions
        .map((item) => item.result.toUpperCase())
        .join(',')
    },
    isValidResult(result) {
      if (result === ',,,,') {
        return false
      } else {
        return true
      }
    },
    // 根据答案显示选项
    getAnswerOptions() {
      // 获取问题中子问题数量
      const count = this.questions[this.curQNum].sub_count
      // 解析该问题中的子问题答案
      const answers = this.questions[this.curQNum].q_answer.split(',')
      // 解析该问题中子问题的回答
      const result = this.questions[this.curQNum].q_result.split(',')
      if (result.length < count) {
        // 答题信息为空，则设置为空
        for (let i = result.length; i < count; i++) {
          result.push('')
        }
      }
      // 清空当前问题选项
      this.answerOptions.length = 0
      for (let i = 0; i < count; i++) {
        const input = {
          index: i,
          title: '' + (i + 1),
          result: result[i],
          answer: answers[i],
        }
        this.answerOptions.push(input)
      }
    },
  },
  extends: BaseComponent,
}
</script>

<style lang="scss" scoped>
</style>
