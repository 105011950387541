<template>
  <div id="codeCalcComponent-m">
    <h3 class="mt-5 grey--text">{{ testConfig.title }}</h3>
    <v-container class="mt-5">
      <!-- 题目数量及定时器 -->
      <v-row v-show="isTestData">
        <v-col cols="6">
          <h1 class="mt-3 subtitle-1 grey--text">
            第 {{ curQNum + 1 }} 题，共 {{ qCount }} 题
          </h1>
        </v-col>
        <v-col offset="1" ms="4">
          <time-counter-component
            :counterStart="counterStart"
            :intervalMs="timerIntervalMs"
          >
          </time-counter-component>
        </v-col>
      </v-row>
      <!-- 题目说明，未开始测试时显示 -->
      <v-row class="mt-5">
        <img
          v-if="!isTestData"
          v-bind:src="qImg"
          style="
            display: inline-block;
            width: 90%;
            max-width: 90%;
            height: 400px;
            margin: 0 auto;
          "
        />
        <!-- 代码图片示例 -->
        <div id="code-images" class="mt-5" v-if="isTestData">
          <table border="1" cellspacing="5" align="center">
            <tr>
              <td
                v-for="(item, i) in codeImages"
                :key="'codeidx' + i"
                align="center"
                class="codeIndex"
                bgcolor="#e9faff"
              >
                {{ i }}
              </td>
            </tr>
            <tr>
              <td
                v-for="(item, i) in codeImages"
                :key="'codeImage' + i"
                bgcolor="#e9faff"
              >
                <img
                  v-bind:src="getCodeImage(item)"
                  style="
                    display: inline-block;
                    width: 100%;
                    max-width: 100%;
                    margin: 0 auto;
                  "
                />
              </td>
            </tr>
          </table>
        </div>
        <!-- 显示题目： AA + BB = ? -->
        <div id="question-images" class="mt-15" v-if="isTestData">
          <!-- 显示第一个参数 -->
          <v-avatar
            rounded=""
            v-for="(item, i) in parmAImages"
            :key="'parmA' + i"
            class="ml-2"
          >
            <img v-bind:src="getCodeImage(item)" />
          </v-avatar>
          <!-- 显示符号 -->
          <v-avatar rounded="" class="ml-5 mr-5">
            <img v-bind:src="getLocalImg" />
          </v-avatar>
          <!-- 显示第二个参数 -->
          <v-avatar
            rounded=""
            v-for="(item, i) in parmBImages"
            :key="'parmB' + i"
            class="ml-2"
          >
            <img v-bind:src="getCodeImage(item)" />
          </v-avatar>
          <!-- 显示等号 -->
          <!-- <v-avatar rounded="" class="ml-10 mr-8">
            <img src="../../assets/signImages/sign_eql.png" />
          </v-avatar> -->
          <!-- 显示问号 -->
          <!-- <v-avatar rounded="" class="ml-8">
            <img src="../../assets/signImages/sign_qmark.png" />
          </v-avatar> -->
        </div>
      </v-row>
      <v-row>
        <v-container v-if="isTestData">
          <v-radio-group v-model="selectAnswer" row>
            <v-radio
              v-for="(item, i) in answerOptions"
              :key="'answerOptions' + i"
              :label="item"
              :value="item"
              style="margin-right: 3%; margin-left: 3%"
            ></v-radio>
          </v-radio-group>
        </v-container>
      </v-row>
      <v-row>
        <v-btn
          style="margin-left: 5%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="preQuestion()"
          :disabled="disablePreBtn()"
        >
          上一题
        </v-btn>
        <v-btn
          style="margin-left: 20%"
          width="35%"
          height="60px"
          color="primary"
          v-if="!disableNavBtn"
          @click="nextQuestion()"
          :disabled="disableNextBtn()"
        >
          下一题
        </v-btn>
      </v-row>

      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-if="isTestStart"
          @click="doneTest()"
          :loading="loadingSubmit"
        >
          提交答案
        </v-btn>
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin-left: 5%"
          width="35%"
          height="60px"
          color="primary"
          v-show="checkErrors"
          @click="setAnswerVisible()"
        >
          <span v-if="!answerVisible">显示答案</span>
          <span v-if="answerVisible">隐藏答案</span>
        </v-btn>
        <v-text-field
          max-width="20%"
          label="正确答案"
          style="margin-right: 5%; margin-left: 5%"
          v-model="correctAnswer"
          v-if="showAnswer"
          readonly
        ></v-text-field>
      </v-row>
      <v-row class="mt-5">
        <v-btn
          style="margin: 0 auto"
          width="90%"
          height="60px"
          color="primary"
          v-show="!isTestStart"
          @click="startTest()"
        >
          开始测试
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent'
import { APP_PREFIX } from '@/const'
import timeCounterComponent from '@/components/common/timeCounterComponent'

const signImageMap = {
  add: 'sign_add.png',
  sub: 'sign_sub.png',
  mul: 'sign_mul.png',
  div: 'sign_div.png',
  eql: 'sign_eql.png',
  qmark: 'sign_qmark.png',
}

export default {
  components: { timeCounterComponent },

  data() {
    return {
      // 代码模板对应的图片
      codeImages: [],
      // 每一道具体问题对应的图片
      parmAImages: [],
      parmBImages: [],
      // 符号图片
      signImage: '',
    }
  },
  methods: {
    // 重载方法：问题序号更新
    procQnumUpdate() {
      this.getQuestionImage()
    },
    // 重载方法：开始新测试
    procNewTest() {
      this.getQuestionImage()
    },
    // 重载方法: 初始化测试数据
    specificInitTestData() {
      this.codeImages = []
      this.parmAImages = []
      this.parmBImages = []
      this.signImage = ''
    },
    // 重载方法: 初始化新测试数据
    specificSetStartTestData() {
      this.codeImages = this.testData.test_desc.split(',')
    },
    // 获取问题图片
    getQuestionImage() {
      if (this.questions === null) {
        // 问题列表为空，返回
        return
      }
      // eslint-disable-next-line quotes
      const question = JSON.parse(
        this.questions[this.curQNum].q_info.replaceAll("'", '"')
      )
      // 获取第一个参数图片, 可能存在多个图片
      this.parmAImages = this.getImageForParm(question.pa)
      // 获取第二个参数图片, 可能存在多个图片
      this.parmBImages = this.getImageForParm(question.pb)
      // 符号图片
      this.signImage = this.getImageForSign(question.m)
    },
    // 根据问题参数获取图片
    getImageForParm(parm) {
      const strParm = parm.toString()
      const images = []
      for (const item of strParm) {
        const image = this.codeImages[parseInt(item)]
        images.push(image)
      }
      return images
    },
    // 根据运算符号获取图片
    getImageForSign(sign) {
      if (sign) {
        return signImageMap[sign]
      }
      return ''
    },
    // 根据答案显示选项
    getAnswerOptions() {
      // eslint-disable-next-line quotes
      const question = JSON.parse(
        this.questions[this.curQNum].q_info.replaceAll("'", '"')
      )
      this.answerOptions = question.o.split(',')
      this.selectAnswer = this.questions[this.curQNum].q_result
    },
    // 获取图片地址
    getCodeImage(image) {
      // return SERVER_CONFIG + '/' + APP_PREFIX + '/' + image
      return '/' + APP_PREFIX + '/' + image
    },
  },
  computed: {
    getLocalImg() {
      if (this.signImage === '') {
        return this.signImage
      }
      return require('../../assets/signImages/' + this.signImage)
    },
  },
  extends: BaseComponent,
}
</script>

<style lang="scss" scoped>
.codeIndex {
  font-family: 微软雅黑;
  font-size: 26px;
  font-weight: bold;
  border-bottom: 1px dashed #cccccc;
  color: #255e95;
}
</style>
